<template>
  <div class="navbar-container d-flex align-items-center justify-content-between">
    <div class="responsiveBlock d-xl-none">
      <!-- Left Col -->
      <div
        class="bookmark-wrapper"
      >
        <slot name="logo" />
      </div>

      <!-- Right Col -->
      <div
        class="d-flex align-items-center justify-content-end"
      />
    </div>
    <b-navbar-nav class="header__nav-bard d-none d-xl-flex nav align-items-center justify-content-between ml-auto">
      <!-- Left Col -->
      <div
        class="bookmark-wrapper d-flex align-items-center"
      >
        <slot name="logo" />
      </div>
    </b-navbar-nav>
  </div>
</template>

<script>
import { BNavbarNav } from 'bootstrap-vue'
// import Locale from './components/Locale.vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api/dist/vue-composition-api'
// import { getUserData } from '@/auth/utils'
import warehouseModule from '@/store/settings/warehouse'

export default {
  components: {
    BNavbarNav,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      themeColor: localStorage.getItem('storageThemeColor'),
    }
  },
  created() {
    // this.$store.dispatch('horizontalMenu/getWarehousesList').finally(() => {
    //   this.loader = false
    // })
    // store.dispatch('horizontalMenu/getMe').then(response => {
    //   const { data } = response.data
    //   store.commit('app/SET_PERMISSIONS', this.getActions(data))
    // })
  },
  methods: {
    getActions(permissions) {
      if (permissions.length === 0) {
        return []
      }
      return permissions?.filter(item => item.subject === 'Allow Switch Warehouses')
    },
  },
  setup() {
    const MODULE_NAME_WAREHOUSE = 'warehouse'
    if (!store.hasModule(MODULE_NAME_WAREHOUSE)) store.registerModule(MODULE_NAME_WAREHOUSE, warehouseModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME_WAREHOUSE)) store.unregisterModule(MODULE_NAME_WAREHOUSE)
    })
  },
}
</script>
<style>
.nav-item {
  list-style: none;
}

.navbar-container {
  display: flex;
  align-items: center;
}

.responsiveBlock {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

@media all and (max-width: 364px) {
  .responsiveBlock {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }
}

.header__nav-bard {
  width: 100%;
}
</style>
